import { useState, useEffect } from 'react';
import axios from "axios";
import { Container} from '@mui/material';
import Scrollbar from './Scrollbar';
import Page from './Page';
import '../theme/formStyle.css'

export default function NewAds(){    

const query = 'https://api.sportlinkapp.com/ads/post';
  
  const [data, setData] = useState({
    adTitle: "",
    advertiser: "",
    text: "",
    startDate: "",
    endDate: "",
    showPerDay: "",
    priority: ""
  });

  const [file,setFile] = useState(null);

  function submit(e){
    e.preventDefault();
    const bodyFormData = new FormData()
    bodyFormData.set('action', 'handlefrontendajax');
    bodyFormData.set('adTitle', data.adTitle);
    bodyFormData.set('advertiser', data.advertiser);
    bodyFormData.set('text', data.text);
    bodyFormData.set('startDate', data.startDate);
    bodyFormData.set('endDate', data.endDate);
    bodyFormData.set('showPerDay', data.showPerDay);
    bodyFormData.set('priority', data.priority);
    bodyFormData.set('file', file);
    console.info(file);
    if (typeof window !== 'undefined') {
      window.stopProceessing = true;
    };

  axios({
    method: 'post',
    url: query,
    data: bodyFormData,
    headers: {
        'Contet-Type': 'multipart/form-data',
        'authorization': `Bearer ${localStorage.token}`,
    },
  })
  .then((response) =>{
    window.location ="/dashboard/ads";
  })
  .catch((err)=>{
    console.info(err);
    console.info(bodyFormData);
  })
  };

  function handle(e){
    const newdata={...data};
    newdata[e.target.name] = e.target.value;
    setData(newdata);
    console.info(newdata);
  };

  function handleFileSelect(event){
    setFile(event.target.files[0]);
    console.log(event.target.files[0]);
  };

    return(              
        <Page title="New Advertisment">
            <Container>
                <div className="test">
                    <h2>New Advertisment</h2>
                    <form onSubmit={(e)=>submit(e)}>
                        <div className='marginTen'>
                          <span>Advertisment Title</span>
                            <input onChange={(e)=>handle(e)} value={data.adTitle} name="adTitle" placeholder="Title" type="text" className='formInput'/>
                        </div>
                        <div className='marginTen'>
                        <span>Advertiser name</span>
                            <input onChange={(e)=>handle(e)} value={data.advertiser} name="advertiser" placeholder="Advertiser" type="text"  className='formInput'/>
                        </div>
                        <div className='marginTen'>
                        <span>Advertisment Description</span>
                            <input onChange={(e)=>handle(e)} value={data.text} name="text" placeholder="Description" type="Description" className='formInput'/>
                        </div>
                        <div className='marginTen'>
                        <span>Advertisment Start date</span>
                            <input onChange={(e)=>handle(e)} value={data.startDate} name="startDate" type="date" className='formInput'/>
                        </div>
                        <div className='marginTen'>
                        <span>Advertisment End date</span>
                            <input onChange={(e)=>handle(e)} value={data.endDate} name="endDate" type="date" className='formInput'/>
                        </div>
                        <div className='marginTen'>
                        <span>Frequency of showing per day</span>
                            <input onChange={(e)=>handle(e)} value={data.showPerDay} name="showPerDay" type="number" className='formInput'/>
                        </div>
                        <div className='marginTen'>
                        <span>Priority</span>
                            <select onChange={(e)=>handle(e)} name="priority" className='formInput'>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                            </select>
                        </div>
                        <h4>Ads Image</h4>
                            <input type="file" name="file" onChange={handleFileSelect} className='marginTen'/>
                        <div>
                            <input type="submit" value="Submit" className='submitBtn'/>
                        </div>
                    </form>
                </div>
            </Container>
        </Page>
    )
}


