import { useState, useEffect } from 'react';
import axios from "axios";
import { useSearchParams } from 'react-router-dom';
import { Container} from '@mui/material';
import { EditorState,ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Page from './Page';
import '../theme/formStyle.css'


export default function EditTerms(props){
    const [searchParams, setSearchParams] = useSearchParams();
    
    const editQuery=`https://api.sportlinkapp.com/term/findId/${searchParams.get("id")}`;

    const [headerLoading, setHeaderLoading] = useState(false);
    const [editInfo, setEditInfo] = useState({
		id: "",
		title: "",
		description: "",
		summary: "",
	});

    useEffect(()=>{
        setHeaderLoading(true);
        axios({
            method: 'get',
            url: editQuery,
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${localStorage.token}`,
            },
        })
          .then(res=>{
            setEditInfo({
                id: res.data._id,
                title: res.data.title,
                description: res.data.description,
                summary: res.data.summary,
            });
            setHeaderLoading(false);
          })
        },[]);

    const edited = `https://api.sportlinkapp.com/term/updateId/${editInfo.id}`;

	function editSubmit(e){
		e.preventDefault();
        const jsonData = JSON.stringify({
            title: editInfo.title,
            description: editedState,
            summary: editInfo.summary,
        })
        
        axios({
            method: 'post',
            url: edited,
            data: jsonData,
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${localStorage.token}`,
            },
        })
        .then((response) =>{
            window.location ="/dashboard/terms";
        })
        .catch((err)=>{
            alert(err.response.data);
        });
    };

    function handleEdit(e){
        const newdata={...editInfo};
        newdata[e.target.name] = e.target.value;
        setEditInfo(newdata);
    };
    const [blocksFromHtml, setBlocksFromHtml] = useState(htmlToDraft("Wait..."));

    
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState))

    const changeContent=()=>{
        const test = htmlToDraft(editInfo.description);
        const { contentBlocks, entityMap } = test;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        setEditorState(EditorState.createWithContent(contentState));
    }

    useEffect(() => {
		const timer = setTimeout(() => changeContent(), 500);
		return () => clearTimeout(timer);
    }, [editInfo]);

    const [editedState, setEditedState] = useState(null);

    const onEditorStateChange = editorState=>{
        setEditorState(editorState);
        setEditedState(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    }
    return(              
        <Page title="Edit Terms">
            <Container>
                <div>
                    <p>Edit</p>
                    <form onSubmit={(e)=>editSubmit(e)}>
                        <span>Term title</span>
                        <div className='marginTen'>
                            <input onChange={(e)=>handleEdit(e)} defaultValue={editInfo.title} name="title" type="text" className='formInput' required />
                        </div>
                        <span>Term summary</span>
                        <div className='marginTen'>
                            <input onChange={(e)=>handleEdit(e)} defaultValue={editInfo.summary} name="summary" type="text" className='formInput' required />
                        </div>
                        <span>Term description</span>
                        { headerLoading ? (
				            <p>"Loading..."</p>
			            ):(<div className='marginTen'>
                            <Editor
                                editorState={editorState}
                                editorStyle={{background:"White"}}
                                onEditorStateChange={onEditorStateChange}
                            />
                        </div>)}
                        <input type="submit" value="Edit sport" className='submitBtn'/>
                    </form>
                </div>
            </Container>
        </Page>
    )
}


