import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//

import User from './pages/User';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register';

import Terms from './pages/Terms';
import NewTerms from './components/NewTerms';
import EditTerms from './components/EditTerms';
import Sports from './pages/Sports';
import EditSport from './components/EditSport';
import NewSport from './components/NewSport';
import Ads from './pages/Ads';
import EditAds from './components/EditAds';
import NewAds from './components/NewAds';
import EditUser from './components/EditUser';
import Homepage from './components/HomePage';
import ReportedUsers from './pages/Flagged';
import Privacy from './pages/Privacy';

import Chat from './pages/Chat';

import DashboardApp from './pages/DashboardApp';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: 'user', element: <User /> },
        { path: 'ads', element: <Ads /> },
        { path: 'sports', element: <Sports /> },
        { path: 'new-sport', element: <NewSport /> },
        { path: 'new-advertisment', element: <NewAds /> },
        { path: 'edit-sport', element: <EditSport /> },
        { path: 'edit-ads', element: <EditAds /> },
        { path: 'terms', element: <Terms /> },
        { path: 'new-terms', element: <NewTerms /> },
        { path: 'edit-terms', element: <EditTerms /> },
        { path: 'edit-user', element: <EditUser /> },
        { path: 'chat', element: <Chat /> },
        { path: 'reported-users', element: <ReportedUsers /> },
      ],
    },
    {
      path: '/',
      children: [
        { path: '/', element: <Homepage />  },
        { path: '/privacy', element: <Privacy />  },
      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
