import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
// material
import {
  Stack,
  Container,
  Typography,
} from '@mui/material';
// components
import Page from '../components/Page';

export default function Chat() {
  const navigate = useNavigate();
  const [userName, setUserName] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    localStorage.setItem('userName', userName);
    navigate('/dashboard/chat');
  };
  return (
    <Page title="User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Chat
          </Typography>
          <form className="home__container" onSubmit={handleSubmit}>
            <h2 className="home__header">Sign in to Open Chat</h2>
            <input
              type="text"
              minLength={6}
              name="username"
              id="username"
              className="username__input"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
            />
            <button className="home__cta">SIGN IN</button>
          </form>
        </Stack>
      </Container>
    </Page>
  );
}
