import * as Yup from 'yup';
import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
// material
import { Stack, TextField, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

export default function RegisterForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const RegisterSchema = Yup.object().shape({
      first_name: Yup.string()
        .required("First Name is Required.")
        .min(1, "First Name is Too Short."),
      last_name: Yup.string()
        .required("Last Name is Required.")
        .min(1, "Last Name is Too Short."),
      email: Yup.string().email().required("Email is Required."),
      password: Yup.string()
        .required("No password provided.")
        .min(8, "Password is too short - should be 8 chars minimum.")
        .matches(/(?=.*[0-9])/, "Password must contain a number."),
  });
  

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      password: '',
    },
    validationSchema: RegisterSchema,
    onSubmit: () => {
      navigate('/dashboard', { replace: true });
    },
  });

  const registerLink = "https://api.sportlinkapp.com/register";

  function handleSubmit(e){
		e.preventDefault();
    const bodyFormData = new FormData()
		bodyFormData.set('action', 'handlefrontendajax');
		bodyFormData.set('first_name', formik.values.first_name);
		bodyFormData.set('last_name', formik.values.last_name);
		bodyFormData.set('email', formik.values.email);
		bodyFormData.set('password', formik.values.password);
	axios({
		method: 'post',
		url: registerLink,
		data: bodyFormData,
		headers: {
			'Contet-Type': 'multipart/form-data',
		},
	})
	.then((response) =>{
    window.location.href = '/login';
    const token  =  response.data.token;
    localStorage.setItem("token", token);
	})
  .catch(err => alert(err.response.data));
	};

  const { errors, touched, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={(e)=>handleSubmit(e)}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="First name"
              {...getFieldProps('first_name')}
              error={Boolean(touched.first_name && errors.first_name)}
              helperText={touched.first_name && errors.first_name}
            />
          </Stack>
          <TextField
              fullWidth
              label="Last name"
              {...getFieldProps('last_name')}
              error={Boolean(touched.last_name && errors.last_name)}
              helperText={touched.last_name && errors.last_name}
            />
          <TextField
            fullWidth
            type="email"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            type="password"
            label="Password"
            {...getFieldProps('password')}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />

          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
            Register
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
