import { useState, useEffect} from 'react';
import axios from 'axios';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
// material
import { Container, Stack, Typography, Button } from '@mui/material';
// components
import Page from '../components/Page';
import  { UserListHead, UserListToolbar, UserMoreMenu, ADSMoreMenu }  from '../sections/@dashboard/user';


import Iconify from '../components/Iconify';

// ----------------------------------------------------------------------

export default function Advertisment() {
  const [openFilter, setOpenFilter] = useState(false);

      /*  */
  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const [adv,setAdv] = useState([]);

  const queryAdv = 'https://api.sportlinkapp.com/ads/display';
    
    useEffect(()=>{
      axios({
        method: 'get',
        url: queryAdv,
        headers: {
            'authorization': `Bearer ${localStorage.token}`,
        },
      })
        .then(res=>{
          setAdv(res.data);
        })
      },[]);
  const imageDomain=process.env.REACT_APP_IMAGE_DOMAIN;

  return (
    <Page title="Ads">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" sx={{ mb: 5 }} style={{marginBottom: "0px"}}>
            Advertisment
          </Typography>
          <Button variant="contained" component={RouterLink} to="/dashboard/new-advertisment" startIcon={<Iconify icon="eva:plus-fill" />}>
              New Advertisment
          </Button>
        </Stack>
        {
          adv.map((item)=>(
            <div key={item._id} style={{boxShadow: "0 4px 8px 0 rgb(0,0,0,0.2)", transition:"0.3s",width:"40%", marginBottom:"20px"}}>
              <div style={{padding: "2px 16px"}} key={item._id}>
                <div key={item._id} style={{display: "flex", justifyContent: "space-between"}}>
                  <h2>{item.adTitle}</h2>
                  <ADSMoreMenu rowData={item}/>
                </div>
                <h4>{item.advertiser}</h4>
                <p>Start date: {moment(item.startDate).format('MM-DD-YYYY')} , End date: {moment(item.endDate).format('MM-DD-YYYY')}</p>
                <p>Frequency to show per day: {item.frequency}</p>
                <p>Priority: {item.priority}</p>
                <img src={`${imageDomain}${item.image}`} alt={item.advertiser} width="75px"/>
              </div>
            </div>
          ))
        }
      {/* 
        <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
            <ProductFilterSidebar
              isOpenFilter={openFilter}
              onOpenFilter={handleOpenFilter}
              onCloseFilter={handleCloseFilter}
            />
            <ProductSort />
          </Stack>
        </Stack>

        <ProductList products={PRODUCTS} />
        <ProductCartWidget /> */}
      </Container>
    </Page>
  );
}
