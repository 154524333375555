import { useState, useEffect } from 'react';
import axios from "axios";
import { useSearchParams } from 'react-router-dom';
import { Container} from '@mui/material';

import Page from './Page';
import '../theme/formStyle.css'


export default function EditSport(props){
    const [searchParams, setSearchParams] = useSearchParams();
    
    const editQuery=`https://api.sportlinkapp.com/ads/findId/${searchParams.get("id")}`

    const [editInfo, setEditInfo] = useState({
		id: "",
		adTitle: "",
		advertiser: "",
		text: "",
		image: "",
        startDate: "",
        endDate: "",
        showPerDay: ""
	});
    
  

    useEffect(()=>{
        axios({
            method: 'get',
            url: editQuery,
            headers: {
                'authorization': `Bearer ${localStorage.token}`,
            },
        })
          .then(res=>{
            setEditInfo({
                id: res.data._id,
                adTitle: res.data.adTitle,
                advertiser: res.data.advertiser,
                text: res.data.text,
                image: res.data.image,
                startDate: res.data.startDate,
                endDate: res.data.endDate,
                showPerDay: res.data.frequency
            });
          })
        },[]);

    const edited = `https://api.sportlinkapp.com/ads/updateId/${editInfo.id}`;

    const [file,setFile] = useState(null);
	function editSubmit(e){
		e.preventDefault();
		const bodyFormData = new FormData()
		bodyFormData.set('action', 'handlefrontendajax');
		bodyFormData.set('id', editInfo.id);
		bodyFormData.set('adTitle', editInfo.adTitle);
		bodyFormData.set('advertiser', editInfo.advertiser);
		bodyFormData.set('text', editInfo.text);
        bodyFormData.set('startDate', editInfo.startDate);
        bodyFormData.set('endDate', editInfo.endDate);
        bodyFormData.set('showPerDay', editInfo.showPerDay);
        bodyFormData.set('priority', editInfo.priority);
        bodyFormData.set('file', file);
        if (typeof window !== 'undefined') {
          window.stopProceessing = true;
        };
        axios({
            method: 'post',
            url: edited,
            data: bodyFormData,
            headers: {
                'Content-type' : 'multipart/form-data',
                'authorization': `Bearer ${localStorage.token}`,
            },
        })
        .then((response) =>{
            window.location ="/dashboard/ads";
        })
        .catch((response)=>{
            alert(response.response.data.error);
        });
    };

    function handleEdit(e){
        const newdata={...editInfo};
        newdata[e.target.name] = e.target.value;
        setEditInfo(newdata);
    };

    function handleFileSelect(event){
        setFile(event.target.files[0]);
        console.log(event.target.files[0]);
      };

    const imageDomain=process.env.REACT_APP_IMAGE_DOMAIN;
    return(              
        <Page title="Edit Advertisment">
            <Container>
                <div>
                    <p>Edit</p>
                    <form onSubmit={(e)=>editSubmit(e)}>
                        <span>Advertisment title</span>
                        <div className='marginTen'>
                            <input onChange={(e)=>handleEdit(e)} defaultValue={editInfo.adTitle} name="adTitle" type="text" className='formInput' required />
                        </div>
                        <span>Advertiser name</span>
                        <div className='marginTen'>
                            <input onChange={(e)=>handleEdit(e)} defaultValue={editInfo.advertiser} name="advertiser" type="text" className='formInput' required />
                        </div>
                        <span>Advertisment description</span>
                        <div className='marginTen'>
                            <input onChange={(e)=>handleEdit(e)} defaultValue={editInfo.text} name="text" type="textarea" className='formInput' required />
                        </div>
                        <div className='marginTen'>
                        <span>Advertisment Start date</span>
                            <input onChange={(e)=>handleEdit(e)} value={editInfo.startDate} name="startDate" type="date" className='formInput'/>
                        </div>
                        <div className='marginTen'>
                        <span>Advertisment End date</span>
                            <input onChange={(e)=>handleEdit(e)} value={editInfo.endDate} name="endDate" type="date" className='formInput'/>
                        </div>
                        <div className='marginTen'>
                        <span>Frequency of showing per day</span>
                            <input onChange={(e)=>handleEdit(e)} value={editInfo.showPerDay} name="showPerDay" type="number" className='formInput'/>
                        </div>
                        <div className='marginTen'>
                        <span>Priority</span>
                            <select onChange={(e)=>handleEdit(e)} name="priority" className='formInput'>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                            </select>
                        </div>
                        <h4>Ads Image</h4>
                            <input type="file" name="file" onChange={handleFileSelect} className='marginTen'/>
                            <p>Previous photo: <img src={`${imageDomain}${editInfo.image}`} alt={editInfo.image} width="35px"/></p>
                        <input type="submit" value="Edit sport" className='submitBtn'/>
                    </form>
                </div>
            </Container>
        </Page>
    )
}


