import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Link, Container, Typography } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
import Logo from '../components/Logo';
// sections
import { LoginForm } from '../sections/auth/login';
import AuthSocial from '../sections/auth/AuthSocial';
import LogoImg from '../assets/img/SportLink-logo.png'
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 1200,
  margin: 'auto',
  minHeight: '100vh',
  display: 'block',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));



// ----------------------------------------------------------------------

export default function Privacy() {
  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');

  return (
    <Page title="Privacy">
      <RootStyle>
        <Container maxWidth="xl">
          <ContentStyle>
          <img src={LogoImg} alt="Logo" style={{height: '70px', marginBottom: '50px'}}/>
SPORTLINK PRIVACY POLICY<br/><br/><br/>
Last Updated February 2, 2023<br/>
SportLink respects your privacy. This Privacy Policy explains how we treat information we collect about you through sportlinkapp.com, our mobile application, and other products and services we offer (collectively, our “Services”). Please read it carefully. This Privacy Policy is governed by and part of our Terms of Use and may be updated periodically. We will notify you about material changes to this Privacy Policy by email or by other means and collect your consent to those changes to the extent required by applicable law. The date at the top of this page tells you when this Privacy Policy was last updated. <br/><br/>
POLICY SUMMARY<br/><br/>
SportLink values your privacy, and we want you to understand how we treat and protect your information. Here is a summary of our privacy practices:<br/>
•	This Privacy Policy covers information we collect on Services like our mobile applications, through your e-mail, phone, text, or other communications with us, and your interactions with our ads on other platforms.<br/>
•	We collect most Personal Information from visitors and registered users of the Services. We use this information to provide you with the Services you request and to communicate with you about SportLink.<br/> 
•	Data we collect from your interactions with our Services may be used for analytics or other legitimate interests.<br/> 
•	We do not sell Personal Information to third parties.<br/>
•	We do not share your Personal Information to target ads to you on other platforms.<br/> 
•	This Privacy Policy only applies to SportLink. It does not apply to any third-party platforms or services, including services advertised on or accessible from our Services.<br/>
•	If you have questions about our privacy practices or would like to make a complaint, please contact us at legal@sportlinkapp.com.<br/>
<strong>By accessing the Services in any manner, you agree to our privacy practices as described in this Privacy Policy. If you do not agree with this Privacy Policy, do not access or use the Services.</strong><br/><br/>
<strong>PERSONAL INFORMATION DEFINED</strong><br/><br/>
When we say, “Personal Information,” we mean any information that is protected by privacy law and identifies, relates to, describes, is reasonably capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer or household. Personal Information falls within certain categories, for example:<br/>
•	Identifiers (e.g., name, email, telephone number, address, username); <br/>
•	Employment-related information (e.g., current or past employment);<br/>
•	Legally protected information (e.g., race, citizenship, sex, marital status);<br/>
•	Commercial information (e.g., products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies); <br/>
•	Internet or other similar activity (e.g., browsing history; content interactions); <br/>
•	Sensitive Personal Information (e.g., government identification number; precise geolocation; racial or ethnic origin; religious beliefs; health information; contents of messages when we are not the recipient; in some cases, information about a known child);<br/>
•	Biometrics (e.g., DNA, face/voice prints, health data) and audio, electronic, visual, thermal, or olfactory information;<br/>
•	Non-public educational information, including information protected under the Family Educational Rights and Privacy Act (20 U.S.C. § 1232g, 34 C.F.R. Part 99); and<br/>
•	Inferences drawn from Personal Information to create a profile about preferences, characteristics, trends, predispositions, behavior, attitudes, intelligence, and aptitudes. <br/>
NNot all information is protected as Personal Information. For example, publicly available information, aggregated information (meaning data summaries or reports with Personal Information removed), or anonymized information that cannot be linked back to an individual are often not protected by privacy laws. <br/><br/>  
CATEGORIES AND SOURCES OF PERSONAL INFORMATION<br/><br/>
SportLink collects your Personal Information: (1) with your consent as informed by this Privacy Policy and freely given at the time you provide the information; (2) if we have a legitimate interest in doing so; or (3) as authorized or required by law. We only collect, use, retain, and disclose Personal Information as is adequate and relevant to the specific, express purposes described below or as reasonably necessary and proportionate to provide our Services or for other purposes that we disclose to you and are compatible with the context of how we collected your Personal Information. <br/>
SportLink has recently launched and has limited operating history, but during the past 12 months, we have collected identifiers, commercial information, and internet and similar activity through the Services. Our sources of Personal Information collection include:<br/>
Directly from you when you provide it to us. We collect Personal Information you submit to us with your consent. This includes your account registration and subscription information like name, address, email, phone number, and commercial history about activities you undertake with us. We also collect Personal Information when you fill out an electronic form on our website. If you send us an email, respond to a survey, or publish any content via the Services (“User Contributions”), we will collect the information you include in your submission. We may also ask you for Personal Information when you report a problem with our Services.<br/>
From other apps on your device. SportLink may request access to other apps on your device like your camera, photo gallery, or geolocation. We may also collect your Device ID. SportLink does not use cookies to provide the Services, but SportLink may place a token on the internal memory of your device to facilitate these features. You consent to this data collection when you grant SportLink permission to access apps and features on your device. We use this data to provide certain application features and for analytics purposes. <br/>
Other Uses of Personal Information. In addition to the specific uses noted above, we may also use Personal Information to: (1) personalize your experience on the Services; (2) monitor your compliance with our agreements and policies; (3) maintain the safety, security, and integrity of our technology assets; (4) conduct internal testing or analysis of the Services; (5) respond to law enforcement requests; (6) bring legal action against persons or entities who may be causing injury to you, us, or others as we determine necessary; (7) evaluate or conduct a business transition where Personal Information held by us is among the assets transferred; or (8) fulfill any purpose to which you consent.<br/><br/>
CHILDREN’S PRIVACY<br/><br/>
Our App and other Services are intended for use by individuals 18 and older. SportLink encourages athletes who are Minors to be active and participate in sports events, but at this time we do not offer the Services to these younger athletes. No individual under the age of 18 (“Minor”) should attempt to use the Services or provide any Personal Information to SportLink. If we learn we have collected or received Personal Information from a Minor, we will delete that information. If you believe we might have any information from or about a Minor, please contact us.<br/> <br/>  
DISCLOSURE OF YOUR INFORMATION<br/><br/>
SportLink will only disclose Personal Information to the third parties as described in this section, with your permission, or as required by law. In the preceding 12 months, we have disclosed Personal Information for a business purpose to the following third parties: <br/>
Our service providers. SportLink uses trusted third party service providers like analytics providers and data and email hosting services to support the Services and help us operate our business. These service providers may have access to Personal Information as needed to perform their contractual obligations to us. We prohibit our service providers from selling or disclosing the Personal Information we provide, and we require all service providers to maintain confidentiality standards and appropriate technical and organizational measures to ensure the security of your Personal Information. A list of currently used service providers is available to Customers upon request.<br/>
Advertisers. Third parties may choose to advertise through SportLink and we may disclose your Personal Information as needed for troubleshooting, verification of placements, and attribution. SportLink does not provide your Personal Information to advertisers for the purpose of their advertising directly. <br/>
Law enforcement, and other governmental agencies, as permitted or required by law.<br/>
Other third parties, as permitted by applicable law, for example: if we go through a business transition (e.g., merger, acquisition, or sale of a portion of our assets); to comply with a legal requirement or a court order; when we believe it is appropriate in order to take action regarding illegal activities or prevent fraud or harm to any person; to exercise or defend our legal claims; or for any other reason with your consent.
Aggregated and deidentified data. We may disclose aggregated or deidentified data to third parties without restriction.<br/><br/>
INFORMATION RETENTION PERIODS<br/><br/>
SportLink only retains Personal Information for the minimum period necessary to provide our Services or achieve our business goals. SportLink will retain current user records, including commercial history, as necessary to provide the Services, and following your cancellation of your user account within 120 days will delete unnecessary records for former users. We will endeavor to securely delete data at the conclusion of the applicable retention period, if shorter or longer than our policy. SportLink reserves the right to retain data for longer periods as required by law or court order or if doing so is critical to our business. <br/> <br/>  
YOUR PRIVACY CHOICES<br/><br/>
You may access, correct, or delete any Personal Information that you have provided to us by contacting legal@sportlinkapp.com. Please note: <br/>
Account deletion. We can only delete a user’s Personal Information from our systems by deleting the entire user account. SportLink will not fulfill a request if we believe doing so would violate any law or legal requirement or cause the information to be incorrect.<br/>
Deleting User Contributions. If you delete your User Contributions, copies of your User Contributions may remain viewable in cached and archived pages or might have been copied or stored by other users. <br/>
Revoke consent. If you wish to revoke a consent already given to us, please contact us.<br/>
Emails. SportLink may use your contact information to communicate with you about your use of our Services. For example, we may send you service announcements or administrative communications by email, phone, text, mail, or other means. You understand that you receive these communications as part of your use of the Services. You can unsubscribe at any time by following the link at the bottom of our emails, but we may still need to contact you as a Customer with service announcements related to their account. <br/>
Texting Consent. By providing us with your wireless phone number, you consent to SportLink sending you informational text messages related to the products, Services, or information you have requested from us. The number of texts that we send to you will be based on your circumstances and requests. You can unsubscribe from text messages by replying STOP or UNSUBSCRIBE to any of these text messages. Messaging and data charges may apply to any text message you receive or send. Please contact your wireless carrier if you have questions about messaging or data charges.<br/>
Marketing Communications. We may send you marketing communications by email, mail, or other means in compliance with applicable law. You can opt-out anytime by following the Unsubscribe link in any of our emails or by contacting us.<br/>
Privacy rights. Depending on where you live or are located, you may have certain rights over your Personal Information that we collect and retain. See Consumer Privacy Rights for details. SportLink will facilitate your exercise of those rights as described in Requests to Exercise Privacy Rights.<br/><br/>
CONSUMER PRIVACY RIGHTS<br/><br/>
SportLink is only available to users in the United States. Consumer privacy rights in the United States are provided under state laws and federal laws provide protections relative to certain industries or data uses. This section provides informational notices for under privacy laws for states like California, Colorado, Connecticut, Nevada, Utah, Virginia, and other states that require companies to inform consumers about their privacy rights and provide a method to exercise those rights. Residents of states offering privacy protections (each a “Consumer”) can exercise their privacy rights as applicable to our Services by submitting a Privacy Request. Please note that some privacy laws may not apply to you or to your use of our Services. <br/>
Right to Correct. You have the right to request that we correct inaccurate Personal Information about you on our systems. If you become aware that the Personal Information that we hold about you is incorrect, or if your information changes, please inform us and we will update our records.<br/>
Right to Deletion. You have the right to request that we delete your Personal Information that we collected and retained, with certain exceptions. SportLink may permanently delete, deidentify, or aggregate the Personal Information in response to a request for deletion. <br/>
Right to Access. You have the right to request confirmation that we have collected Personal Information about you and that we provide you with access to that Personal Information. If you submit an access request, we will provide you with copies of the requested pieces of Personal Information in a portable and readily usable format. Please note that SportLink may be prohibited by law from disclosing certain pieces of Personal Information, and we may be limited in the number or frequency of requests we must fulfill. <br/>  
Right to Disclosure. You may request that we disclose information to you about our collection and use of your Personal Information, such as: (a) the categories of Personal Information we have collected about you; (b) the categories of sources for the Personal Information we have collected about you; (c) our business purpose for collecting, using, processing, sharing or selling that Personal Information, as applicable; (d) the categories of third parties with whom we share that Personal Information; and (e) if we sold or shared your Personal Information under the California consumer privacy law, two separate lists stating: (i) sales or sharing, identifying the Personal Information categories that each category of recipient purchased; and (ii) disclosures for a business purpose, identifying the Personal Information categories that each category of recipient obtained. Certain laws may limit the number or frequency of requests we must fulfill.   <br/>
Limited Use and Disclosure of Sensitive Personal Information. You may have the right to opt-out or limit our use of your sensitive Personal Information. SportLink does not seek to collect sensitive Personal Information about any individual, and in no case do we disclose any sensitive Personal Information for the purpose of inferring characteristics about you or otherwise use your sensitive Personal Information without your consent. If this ever changes in the future, we will update this Privacy Policy and provide you with methods to opt-out or limit our use and disclosure of your sensitive Personal Information. <br/>
No Profiling. You may have the right to opt-out of automated profiling. SportLink does not process your Personal Information to evaluate, analyze, or predict your interests and preferences or otherwise use automated profiling to produce significant effects that concern you. If this changes in the future, we will update this Privacy Policy and provide you with a method to opt-out. <br/>
Opt-Out of Selling or Sharing. Some states entitle consumers to opt-out of the sale or sharing of Personal Information or targeted advertising practices. SportLink does not sell your Personal Information to third parties or share your Personal Information with third parties for cross-contextual behavioral advertising purposes. If this changes in the future, we will update this Privacy Policy and provide you with methods to opt-out. <br/>
Right to Nondiscrimination. We will not discriminate against you for exercising your privacy rights. For example, unless permitted by law we will not: (a) deny you goods or services; (b) charge you different prices or rates for goods or services; (c) provide you a different level or quality of goods or services; (d) retaliate against you as an employee, applicant for employment, or independent contractor for exercising your privacy rights; or (e) suggest that you may receive a different price or rate for goods or services or a different level or quality of goods or services, because you exercised a right under applicable privacy laws.<br/>
Right to Disclosure of Marketing Information. California’s Shine the Light Act (Civil Code sections 1798.83-1798.84) entitles California residents to request certain disclosures regarding Personal Information sharing with affiliates and/or third parties for marketing purposes.<br/><br/>
REQUESTS TO EXERCISE PRIVACY RIGHTS<br/><br/>
To exercise your rights under your applicable privacy laws, express concerns, revoke your consent, lodge a complaint, or request information, please contact us.<br/>
We can only assist with or fulfill a privacy request if when we have sufficient information to verify that the requester is the person or an authorized representative of the person about whom we have collected Personal Information, and to properly understand, evaluate, and respond to the request. We do not charge a fee to process or respond to a verifiable request unless we have legal grounds to do so. If we determine that the request warrants a fee, we will tell you why we made that decision and provide you with a cost estimate before completing your request. We endeavor to respond to privacy requests in accordance with the requirements of the law applicable to your jurisdiction. If we do not fulfill your request within the legally required timeline, you can appeal our response by contacting us at legal@sportlinkapp.com. <br/><br/>
DATA PROCESSING IN THE UNITED STATES<br/><br/>
SportLink is owned and operated in the United States. We offer the Services to users in the United States only. If you access the Services from outside the United States, please be aware that your Personal Information may be transferred to, processed, stored, and used in the United States or other jurisdictions. When your information is moved from your home country to another country, the laws and rules that protect your Personal Information in the country to which your information is transferred may be different from those of the country where you live. For example, if your information is in the United States, it may be accessed by government authorities under United States law. By allowing us to collect Personal Information about you, you consent to the transfer and processing of your Personal Information as described in this section.<br/><br/>
DATA SECURITY <br/><br/>
SportLink has implemented and maintains reasonable security measures to secure your Personal Information from accidental loss and unauthorized access, use, alteration, and disclosure. Our security measures are appropriate to the volume, scope, and nature of the Personal Information processed and designed to meet our duty of care with respect to your Personal Information. For example, we use HTTPS for all communications on SportLink and data is secured using JWT tokens. However, data submission over the Internet is never entirely secure. You are responsible for keeping your device access and login information confidential. You are also encouraged to install anti-virus and anti-malware software on your devices and keep all software updated to avoid security risks. We cannot guarantee the security of information you submit via our Services while it is in transit over the Internet, and any such submission is at your own risk.<br/><br/>
THIRD-PARTY SERVICES<br/><br/>
When using our Services, you may have the option to link from or access third-party services. Please note that we have no control over third-party websites, apps, devices, or systems, and you should exercise caution when deciding to disclose your Personal Information.<br/>

          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
