import { useState, useEffect} from 'react';
import axios from 'axios';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Container, Stack, Typography, Button } from '@mui/material';
// components
import Page from '../components/Page';
import  { UserListHead, UserListToolbar, UserMoreMenu, ADSMoreMenu, TermsMoreMenu }  from '../sections/@dashboard/user';


import Iconify from '../components/Iconify';

// ----------------------------------------------------------------------

export default function Advertisment() {
  const [terms,setTerms] = useState([]);

  const queryAdv = 'https://api.sportlinkapp.com/term/display';
    
    useEffect(()=>{
      axios({
        method: 'get',
        url: queryAdv,
        headers: {
            'authorization': `Bearer ${localStorage.token}`,
        },
      })
        .then(res=>{
          setTerms(res.data);
        })
      },[]);
  console.info(terms);

  return (
    <Page title="T&C">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" sx={{ mb: 5 }} style={{marginBottom: "0px"}}>
            T&C
          </Typography>
          <Button variant="contained" component={RouterLink} to="/dashboard/new-terms" startIcon={<Iconify icon="eva:plus-fill" />}>
            T&C 
          </Button>
        </Stack>
        {
          terms.map((item)=>(
            <div key={item._id} style={{boxShadow: "0 4px 8px 0 rgb(0,0,0,0.2)", transition:"0.3s",width:"40%", marginBottom:"20px"}}>
              <div style={{padding: "2px 16px"}} key={item._id}>
                <div key={item._id} style={{display: "flex", justifyContent: "space-between"}}>
                  <h2>{item.title}</h2>
                  <TermsMoreMenu rowData={item}/>
                </div>
                <div dangerouslySetInnerHTML={{ __html: item.description }} />
                <p>Summary: {item.summary}</p>
              </div>
            </div>
          ))
        }
      {/* 
        <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
            <ProductFilterSidebar
              isOpenFilter={openFilter}
              onOpenFilter={handleOpenFilter}
              onCloseFilter={handleCloseFilter}
            />
            <ProductSort />
          </Stack>
        </Stack>

        <ProductList products={PRODUCTS} />
        <ProductCartWidget /> */}
      </Container>
    </Page>
  );
}
