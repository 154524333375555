import { useState, useEffect } from 'react';
import axios from "axios";
import { Container} from '@mui/material';
import '../theme/formStyle.css'
import { EditorState,ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Page from './Page';

export default function NewSport(){    
    const createSport = 'https://api.sportlinkapp.com/sports/addSport';

    const [data, setData] = useState({
        name: "",
        description: "Sport description",
    });

    const [file,setFile] = useState(null);
    const [fileSecond,setFileSecond] = useState(null);

    function submit(e){
    e.preventDefault();
    const bodyFormData = new FormData()
    bodyFormData.set('action', 'handlefrontendajax');
    bodyFormData.set('name', data.name);
    bodyFormData.set('description', editedState);
    bodyFormData.set('file', file);

    if (typeof window !== 'undefined') {
        window.stopProceessing = true;
    };
    bodyFormData.set('fileSecond', fileSecond);

    if (typeof window !== 'undefined') {
        window.stopProceessing = true;
    };

    axios({
        method: 'post',
        url: createSport,
        data: bodyFormData,
        headers: {
            'Contet-Type': 'multipart/form-data',
            'authorization': `Bearer ${localStorage.token}`,
        },
      })
    .then((response) =>{
        window.location ="/dashboard/sports";
    })
    .catch((err)=>{
        console.info(err);
    })
    };

    function handle(e){
        const newdata={...data};
        newdata[e.target.name] = e.target.value;
        setData(newdata);
    };

    function handleFileSelect(event){
        setFile(event.target.files[0]);
    };

    function handleFileSelectSecond(event){
        setFileSecond(event.target.files[0]);
    };

    const [blocksFromHtml, setBlocksFromHtml] = useState(htmlToDraft("Wait..."));

    
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState))

    const changeContent=()=>{
        const html = htmlToDraft(data.description);
        const { contentBlocks, entityMap } = html;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        setEditorState(EditorState.createWithContent(contentState));
    }

    useEffect(() => {
		const timer = setTimeout(() => changeContent(), 500);
		return () => clearTimeout(timer);
    }, [data]);

    const [editedState, setEditedState] = useState(null);

    const onEditorStateChange = editorState=>{
        setEditorState(editorState);
        setEditedState(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    }

    return(              
        <Page title="New Sport">
            <Container>
                <div className="test">
                    <h2>New Sport</h2>
                    <form onSubmit={(e)=>submit(e)}>
                        <div className='marginTen'>
                            <input onChange={(e)=>handle(e)} value={data.name} name="name" placeholder="Title" type="text" className='formInput'/>
                        </div>
                        <div className='marginTen'>
                        <Editor
                                editorState={editorState}
                                editorStyle={{background:"White"}}
                                onEditorStateChange={onEditorStateChange}
                            />
                        </div>
                        <h4>Sport LOGO</h4>
                            <input type="file" name="fileSecond" onChange={handleFileSelectSecond} className='marginTen'/>
                        <h4>Sport Image</h4>
                            <input type="file" name="file" onChange={handleFileSelect} className='marginTen'/>
                        <div>
                            <input type="submit" value="Submit" className='submitBtn'/>
                        </div>
                    </form>
                </div>
            </Container>
        </Page>
    )
}


