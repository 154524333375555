import { useState, useEffect } from 'react';
import axios from "axios";
import { useSearchParams } from 'react-router-dom';
import { Container} from '@mui/material';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Page from './Page';
import '../theme/formStyle.css'


export default function EditSport(props){
    const [searchParams, setSearchParams] = useSearchParams();
    
    const editQuery=`https://api.sportlinkapp.com/sports/findId/${searchParams.get("id")}`

    const [headerLoading, setHeaderLoading] = useState(false);
    const [editInfo, setEditInfo] = useState({
		id: "",
		name: "",
		description: "",
		logo: "",
		image: "",
	});

    useEffect(()=>{
        setHeaderLoading(true);
        axios({
            method: 'get',
            url: editQuery,
            headers: {
                'authorization': `Bearer ${localStorage.token}`,
            },
        })
          .then(res=>{
            setEditInfo({
                id: res.data._id,
                name: res.data.name,
                description: res.data.description,
                logo: res.data.logo,
                image: res.data.image,
            });
            setHeaderLoading(false);
          })
        },[]);

    const edited = `https://api.sportlinkapp.com/sports/updateId/${editInfo.id}`;

    const [file,setFile] = useState(null);
    const [fileSecond,setFileSecond] = useState(null);

	function editSubmit(e){
		e.preventDefault();
		const bodyFormData = new FormData()
		bodyFormData.set('action', 'handlefrontendajax');
		bodyFormData.set('id', editInfo.id);
		bodyFormData.set('name', editInfo.name);
		bodyFormData.set('description', editedState);
        bodyFormData.set('file', file);
        bodyFormData.set('fileSecond', fileSecond);
    
        axios({
            method: 'post',
            url: edited,
            data: bodyFormData,
            headers: {
                'Content-type' : 'multipart/form-data',
                'authorization': `Bearer ${localStorage.token}`,
            },
        })
        .then((response) =>{
            window.location ="/dashboard/sports";
        })
        .catch((response) =>{
            console.info(response);
        })
    };

    function handleEdit(e){
        const newdata={...editInfo};
        newdata[e.target.name] = e.target.value;
        setEditInfo(newdata);
    };

    function handleFileSelect(event){
        setFile(event.target.files[0]);
    };

    function handleFileSelectSecond(event){
        setFileSecond(event.target.files[0]);
    };
    
    const imageDomain=process.env.REACT_APP_IMAGE_DOMAIN;

    const [blocksFromHtml, setBlocksFromHtml] = useState(htmlToDraft("Wait..."));

    
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState))

    const changeContent=()=>{
        const test = htmlToDraft(editInfo.description);
        const { contentBlocks, entityMap } = test;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        setEditorState(EditorState.createWithContent(contentState));
    }

    useEffect(() => {
		const timer = setTimeout(() => changeContent(), 500);
		return () => clearTimeout(timer);
    }, [editInfo]);

    const [editedState, setEditedState] = useState(null);

    const onEditorStateChange = editorState=>{
        setEditorState(editorState);
        setEditedState(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    }
    
    return(              
        <Page title="Edit Sport">
            <Container>
                <div>
                    <p>Edit</p>
                    <form onSubmit={(e)=>editSubmit(e)}>
                        <span>Sport name</span>
                        <div className='marginTen'>
                            <input onChange={(e)=>handleEdit(e)} defaultValue={editInfo.name} name="name" type="text" className='formInput' required />
                        </div>
                        <span>Sport description</span>
                        { headerLoading ? (
				            <p>"Loading..."</p>
			            ):(<div className='marginTen'>
                            <Editor
                                editorState={editorState}
                                editorStyle={{background:"White"}}
                                onEditorStateChange={onEditorStateChange}
                            />
                        </div>)}
                        <h4>Sport LOGO</h4>
                            <input type="file" name="fileSecond" onChange={handleFileSelectSecond} className='marginTen' />
                            <p>Previous photo: <img src={`${imageDomain}${editInfo.logo}`} alt={editInfo.image} width="35px"/></p>
                        <h4>Sport Image</h4>
                            <input type="file" name="file" onChange={handleFileSelect} className='marginTen' />
                            <p>Previous photo: <img src={`${imageDomain}${editInfo.image}`} alt={editInfo.image} width="35px"/></p>
                        <input type="submit" value="Edit sport" className='submitBtn'/>
                    </form>
                </div>
            </Container>
        </Page>
    )
}


