// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'users',
    path: '/dashboard/user',
    icon: getIcon('eva:people-fill'),
  },
  {
    title: 'reported users',
    path: '/dashboard/reported-users',
    icon: getIcon('eva:person-delete-fill'),
  },
  {
    title: 'sports',
    path: '/dashboard/sports',
    icon: getIcon('eva:file-text-fill'),
  },
  {
    title: 'ads',
    path: '/dashboard/ads',
    icon: getIcon('eva:shopping-bag-fill'),
  },  
  {
    title: 'pages',
    path: '/dashboard/terms',
    icon: getIcon('eva:file-text-fill'),
  },
];

export default navConfig;
