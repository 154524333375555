import { useState, useEffect } from 'react';
import axios from "axios";
import { Container} from '@mui/material';
import Page from './Page';
import '../theme/formStyle.css'

export default function NewTerms(){    
  
const query = 'https://api.sportlinkapp.com/term/create';
  
  const [data, setData] = useState({
    title: "",
    description: "",
    summary: "",
  });

  function submit(e){
    e.preventDefault();
    
    const jsonData =JSON.stringify(data);

  axios({
    method: 'post',
    url: query,
    data: jsonData,
    headers: {
        'Content-Type': 'application/json',
        'authorization': `Bearer ${localStorage.token}`,
    },
  })
  .then((response) =>{
    window.location ="/dashboard/terms";
  })
  .catch((err)=>{
    console.info(err);
  })
  };

  function handle(e){
    const newdata={...data};
    newdata[e.target.name] = e.target.value;
    setData(newdata);
    console.info(newdata);
  };

    return(              
        <Page title="New T&C">
            <Container>
                <div className="test">
                    <h2>New T&C</h2>
                    <form onSubmit={(e)=>submit(e)}>
                        <div className='marginTen'>
                          <span>T&C Title</span>
                            <input onChange={(e)=>handle(e)} value={data.title} name="title" placeholder="Title" type="text" className='formInput'/>
                        </div>
                        <div className='marginTen'>
                        <span>T&C Description</span>
                            <input onChange={(e)=>handle(e)} value={data.description} name="description" placeholder="Description" type="textarea" className='formInput'/>
                        </div>
                        <div className='marginTen'>
                        <span>T&C Summary</span>
                            <input onChange={(e)=>handle(e)} value={data.summary} name="summary" placeholder="Summary" type="text" className='formInput'/>
                        </div>
                        <div>
                            <input type="submit" value="Submit" className='submitBtn'/>
                        </div>
                    </form>
                </div>
            </Container>
        </Page>
    )
}


